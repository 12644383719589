export default {
  proxy_path: "https://3iinr6j248.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://u72y6hq76e.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyAcPhL0e5DMG21tUhk_HuTB9L_JWsrvYVc",
  main_company_term_id: "6",
  crm_base_url: "staging.pas.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.pas.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.pas.forwoodsafety.com",
    APP_CLIENT_ID: "4eqjtj1udctnme1qea9ecbgmlj",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://fb5aeo5fw1.execute-api.us-west-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.pas.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
